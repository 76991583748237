export const exampleVideoUrl = "https://www.youtube.com/watch?v=84M1kkKF24k";
export const exampleTabText = `(Capo 3)

Asus2      x02200
F/A        x03211
Asus2sus4  x02430
Gadd4      320010
F7         xx3245
D/F#       200232
    or     200230 Dsus2/F#
Dm/F       100231
    or     100230 Dsus2/F
Eb/G       3xx340
Ebm/Gb     200342
Fadd9      1330xx

[Intro]

Asus2      F/A        Asus2sus4    Gadd4  G

D/F#       Dm/F       A    F/A     D/A    E

A          Fmaj7/A    G            Em/G

Dsus2/F#   F7         A    F       D     E

Bbm        Gb         Abmaj7       Fm

Eb/G       Ebm/Gb     Bb    Gb     Eb7    F

Bb         Gb         Ab           Fm

Ebm/G      Gb7        Bb    Gb     Eb7    F

A          A#         B7           E

A          A#         B7           E

A          A#maj7     B7           E

A          A#maj7     B7           E7

[Verse 1]

Am       Fmaj7/A  Gsus4 G

I hope none  of  you,   think I'm cryin'

D/F#        Dm/F    A    F/A   D/A   E

As the sweat rolls down my face

A        Fmaj7/A   G              Em/G

I hope none  of  you, think I'm losin'

D/F#        Dm/F        A     F/A   D/A  E

when I'm lapping the guy in last place

Bbm      Gb           Ab              Fm

I hope none of you thanks me this winter

Eb/G              Ebm/Gb           Bb    Eb/G   F

For what some of you would hang me from now

Bb        Gb           Ab        Fadd9

Cause today the sun kissed a layer of skin

Eb               Ebm

From your nose, it's cause last night I

Bb           Gb        Eb/G       F/A F/G F/A

chopped     the old     shade        tree

[Chorus]

A    A#    B7    E

Do     -    wn

A    A#    B7    E7

Do    -    wn

A    A#maj7     B7    E7

A    A#maj7     B7    E7

[Interlude]

(identical to intro)

Asus2      F/A        Asus2sus4    Gadd4  G

D/F#       Dm/F       A    F/A     D/A    E

A          Fmaj7/A    G            Em/G

Dsus2/F#   F7         A    F       D     E

Bbm        Gb         Abmaj7       Fm

Eb/G       Ebm/Gb     Bb    Gb     Eb7    F

Bb         Gb         Ab           Fm

Ebm/G      Gb7        Bb    Gb     Eb7    F

A          A#         B7           E

A          A#         B7           E

A          A#maj7     B7           E7

A          A#maj7     B7           E7

(plus this:)

A          F/A        Bm7          G

D/A        Dm/A       A    F       D     E

A          F/A        G              Em/G

D/F#        F7        A    F       D     E

[Verse 2]

Bbm        Gb              Ab             Fm

I guess none of you thought of its roots

Eb/G        Ebm/Gb           Bb    Bbm

As the living dead trapped underground

Bb         Gb           Ab        Fm

Or of the blizzard above or the hero I'd be

Eb/G    Ebm/Gb      Bb    Eb/G   F

Planting bonfires all around town.

Bbm            Gb       Ab             Fm

So as the frost licks the layer of skin off the nose

Eb/G    Gb7

Of your neighbor, remember who

Bb           Gb        Eb/G       F/A F/G F/A

chopped     the old     shade        tree

[Chorus]

A     A#    B7    E

Do     -      wn

A    A#       B7    E7

Do    - wn,  oo-hoo

A    A#maj7     B7    E7

A    A#maj7     B7    E7

[Solo]

A    A#maj7     B7    E7

A    A#maj7     B7    E7

A    A#maj7     B7    E7

A    A#maj7     B7    E7

[Outro]

Am       Fmaj7/A  Gsus4 G

I hope none  of  you,   think I'm cryin'

D/F#        Dm/F    Am

As the sweat rolls down my face`;