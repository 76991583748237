/* SPDX-License-Identifier: GPL-3.0-only */
/* Copyright 2019 Adam Jagosz. https://github.com/hyvyys/chordline */

<template>
  <div class="removable-item">
    <div class="content">
      <slot />
    </div>
    <UiIconButton @click="$emit('remove')" type="secondary" size="small">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" svg-inline="" role="presentation" focusable="false" tabindex="-1"><path d="M19 13H5v-2h14v2z" fill="currentColor"/><path d="M0 0h24v24H0z" fill="none"/></svg>
    </UiIconButton>
  </div>
</template>

<script>
import { UiIconButton } from 'keen-ui';

export default {
  components: {
    UiIconButton,
  },
}
</script>

<style lang="scss" scoped>
.removable-item {
  display: flex;

  .content {
    flex: 1;
    display: flex;
  }

  .ui-icon-button {
    margin: 2px;
  }
}
</style>