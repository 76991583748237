var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chord-diagram"},_vm._l((_vm.strings),function(string,i){return _c('div',{key:i,staticClass:"fretboard-row"},[_c('div',{class:{
        'string-name': true,
        'string-name--hidden': !_vm.displayTuning,
        'order-2': _vm.tuningPosition < -1,
        'order-1': _vm.tuningPosition < 0,
        'order1': _vm.tuningPosition > 0,
        'order2': _vm.tuningPosition > 1,
      }},[_vm._v(" "+_vm._s(_vm.formatNote(string))+" ")]),_c('div',{class:{
        'string-marker' :true,
        'open-marker': _vm.positionAtString(i) === 0,
        'muted-marker': _vm.positionAtString(i) === 'x',
        'open-marker--hidden': !_vm.displayOpenStrings,
        'order-2': _vm.openMutedPosition < -1,
        'order-1': _vm.openMutedPosition < 0,
        'order1': _vm.openMutedPosition > 0,
        'order2': _vm.openMutedPosition > 1,
      }}),_vm._l((_vm.displayedPositions),function(fret){return _c('span',{key:fret,class:{ 'fretboard-cell': true, 'first': fret === 0}},[(fret > 0 && _vm.positionAtString(i) === fret)?_c('div',{class:{
        'pressed-marker': true,
        'is-over-barre': _vm.barre && _vm.barre.fret === fret,
        }},[_c('div',{staticClass:"wrapper"},[_c('span',{class:{ 'note-name': true, 'is-visible': _vm.displayNotes }},[_vm._v(" "+_vm._s(_vm.noteAtString(i))+" ")])])]):_vm._e(),(i === _vm.strings.length - 1 && _vm.barre && _vm.barre.fret === fret)?_c('div',{class:("barre " + _vm.barreClass)}):_vm._e(),(i === _vm.strings.length - 1)?_c('div',{class:{
          'fret-number': true,
          'fret-number--hidden': _vm.minDisplayedPosition === 0,
          'fret-number--visible': _vm.alwaysShowFretNumbers,
        }},[_vm._v(" "+_vm._s(fret)+" ")]):_vm._e()])})],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }